var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('a-layout',[_c('a-layout-sider',{style:({
        overflow: 'auto',
        height: '100%',
        position: 'fixed',
        left: 0,
        zIndex: '1000',
        width: '160px',
        minWidth: '160px',
      }),attrs:{"trigger":null,"collapsible":""},model:{value:(_vm.collapsed),callback:function ($$v) {_vm.collapsed=$$v},expression:"collapsed"}},[_c('a-menu',{attrs:{"theme":"dark","mode":"inline","selectedKeys":[_vm.selectedKeys],"openKeys":_vm.openKeys},on:{"click":_vm.selectClick,"openChange":_vm.onOpenChange}},_vm._l((_vm.list),function(item){return _c('a-sub-menu',{key:item.url},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('a-icon',{attrs:{"type":item.iconType}}),_c('span',[_vm._v(_vm._s(item.name))])],1),_vm._l((item.children),function(menu){return _c('a-menu-item',{key:menu.url},[_vm._v(" "+_vm._s(menu.name)+" ")])})],2)}),1),_c('div',{staticClass:"flex"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.collapsed),expression:"!collapsed"}],staticClass:"showInfo"},[_c('a-dropdown',{staticClass:"dropdown"},[_c('span',{staticStyle:{"cursor":"pointer","padding-left":"10px","font-size":"16px"}},[(_vm.loginAvatarUrl)?_c('a-avatar',{staticClass:"avatar",attrs:{"shape":"circle","src":_vm.loginAvatarUrl}}):_vm._e(),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.userName))])],1),_c('a-menu',{staticStyle:{"width":"150px"},attrs:{"slot":"overlay"},slot:"overlay"},[_c('a-menu-item',[_c('a',{staticStyle:{"display":"inline-block","text-align":"center","width":"100%","padding":"0","margin":"0"},attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.loginOut()}}},[_c('a-icon',{attrs:{"type":"poweroff"}}),_c('span',[_vm._v("退出登录")])],1)])],1)],1)],1),_c('a-icon',{staticClass:"trigger",attrs:{"type":_vm.collapsed ? 'menu-unfold' : 'menu-fold'},on:{"click":() => (_vm.collapsed = !_vm.collapsed)}})],1)],1),_c('a-layout',{class:{
        isContent: true,
        onContent: _vm.collapsed,
      }},[_c('div',{style:({
          background: 'rgb(255, 255, 255)',
          padding: _vm.$route.path == '/panel' ? '' : '0 20px',
        })},[_c('keep-alive',{attrs:{"include":['tabTaskList']}},[(_vm.isComponent)?_c('router-view',{attrs:{"name":"first","collapsed":_vm.collapsed,"loginName":_vm.loginName,"userName":_vm.userName}}):_vm._e()],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }